import paymentMethodByCountry from 'assets/payment-by-country.json'

export const filterPaymentMethodNew = ({ result, countryCode }) => {
  const paymentMethodByCountryData = paymentMethodByCountry[countryCode]?.topPaymentMethod
  let paymentMethodList = result
  // if (!isSafari) {
  //   paymentMethodList = paymentMethodList.filter((payment) => payment.paymentMethod !== 'APPLE_PAY')
  // }

  let defaultSelectedPaymentMethod = paymentMethodList.find((payment) => payment.paymentMethod === paymentMethodByCountryData?.[0])
  if (!defaultSelectedPaymentMethod) {
    defaultSelectedPaymentMethod = paymentMethodList.find((payment) => payment.paymentMethod === paymentMethodByCountryData?.[1])
  }
  if (!defaultSelectedPaymentMethod) {
    defaultSelectedPaymentMethod = paymentMethodList.find((payment) => payment.paymentMethod === paymentMethodByCountryData?.[2])
  }
  if (!defaultSelectedPaymentMethod) {
    defaultSelectedPaymentMethod = paymentMethodList[0]
  }
  return defaultSelectedPaymentMethod
}

export default filterPaymentMethodNew
