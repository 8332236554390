import { detect } from 'detect-browser'

const browser = detect()
export const isSafari = ['safari', 'ios'].includes(browser.name)

export const PROVIDERS = {
  WYRE: 'WYRE',
  MOONPAY: 'MOONPAY',
  RAMP: 'RAMP',
  BTCDIRECT: 'BTCDIRECT',
  BANXA: 'BANXA',
  XANPOOL: 'XANPOOL',
  SIMPLEX: 'SIMPLEX',
  TRANSAK: 'TRANSAK',
  ONRAMPMONEY: 'ONRAMPMONEY',
  SARDINE: 'SARDINE',
  BINANCECONNECT: 'BINANCECONNECT',
  TRANSFI: 'TRANSFI',
  UNLIMIT: 'UNLIMIT',
  PAYBIS: 'PAYBIS',
  FONBNK: 'FONBNK',
  KOYWE: 'KOYWE',
  ROBINHOOD: 'ROBINHOOD',
  COINBASEPAY: 'COINBASEPAY',
  MESH: 'MESH',
  STRIPE: 'STRIPE',
  BLOCKCHAINDOTCOM: 'BLOCKCHAINDOTCOM',
  BILIRA: 'BILIRA',
  ALCHEMYPAY: 'ALCHEMYPAY',
  ONMETA: 'ONMETA',
  YELLOWCARD: 'YELLOWCARD',
  PAYPAL: 'PAYPAL',
  TOPPER: 'TOPPER',
  GUARDARIAN: 'GUARDARIAN',
  SKRILLCRYPTO: 'SKRILLCRYPTO',
  MESO: 'MESO',
  SHIFT4: 'SHIFT4',
  COINFLOW: 'COINFLOW',
  HARBOUR: 'HARBOUR',
}

const PRELOAD_MAP = {
  'localhost:3002': process.env.REACT_APP_MELD_DEFAULT_PUBLIC_KEY,
  'uniswap-sb.meldcrypto.com': process.env.REACT_APP_UNISWAP_DEFAULT_PUBLIC_KEY,
  'uniswap.meldcrypto.com': process.env.REACT_APP_UNISWAP_DEFAULT_PUBLIC_KEY,
}

// We put the key in an object so that it can be spread underneath, and therefore overriden by, the urlParams
const urlSearchParams = new URLSearchParams(window.location.search)
const queryParams = Object.fromEntries(urlSearchParams.entries())

export const defaultPublicKey = { publicKey: queryParams?.publickey || queryParams?.publicKey || PRELOAD_MAP[window?.location?.host] || process.env.REACT_APP_MELD_DEFAULT_PUBLIC_KEY }

export const walletTagCurrencies = ['EOS', 'STX', 'XLM', 'XMR', 'XRP', 'BNB', 'XEM', 'HBAR']

export const redirectServiceProvider = ['BINANCECONNECT', 'MOONPAY', 'ROBINHOOD', 'COINBASEPAY', 'STRIPE', 'BLOCKCHAINDOTCOM', 'BILIRA', 'BTCDIRECT', 'SKRILLCRYPTO', 'MESO', 'PAYPAL', 'TRANSAK']

export const serviceProviderNonTrasactionRedirect = ['moonpay', 'blockchain.com', 'bilira', 'stripe','coinbase pay', 'btcdirect', 'skrill cyprto', 'meso', 'binance connect', 'paypal', 'transak', 'robinhood']

export const serviceProviderNonTrasaction = ['moonpay', 'robinhood', 'coinbase pay', 'blockchain.com', 'bilira', 'stripe', 'btcdirect', 'skrill cyprto', 'meso', 'binance connect', 'paypal', 'transak']

export const localSubTypePaymentMethod = ['BANKCARD', 'HK_CARD', 'EU_CARD', 'BR_CARD', 'MX_CARD']

export const symbolCurrencies = ['USD', 'EUR', 'GBP']

export const fallbackReturnUrl = process.env.REACT_APP_WIDGET_FALLBACK_URL

export const defaultPaymentMethodSimplex = 'VISA'

export const turkeyCurrencySymbol = '₺'
export const drawerMobileHeight = 380
export const copyrightText = 'Powered by'
export const isMobileScreen = window.screen.width <= 450

export const pickDefaultSourceAmount = (sourceCurrencyCode) => {
  switch (sourceCurrencyCode) {
    case 'BTC':
      return 0.01
    case 'ETH':
      return 0.1
    default:
      return 100
  }
}

export const amountSectionWidth = (screenWidth) => {
  let width = 85
  if (screenWidth <= 390 && screenWidth > 340) {
    width = 83
  } else if (screenWidth <= 340 && screenWidth > 320) {
    width = 81
  } else if (screenWidth <= 320 && screenWidth > 300) {
    width = 79
  } else if (screenWidth <= 300 && screenWidth > 300) {
    width = 75
  } else if (screenWidth <= 300) {
    width = 70
  }
  return width
}

export const mixPanelEventKeys = {
  endpoint: window.location.href,
  parentEndpoint: document?.location?.ancestorOrigins?.[0] || ''
}

export const walletAddressValidationMsg = 'Ensure your wallet address is compatible to receive your selected tokens.'

export const meldLogo = 'https://fluidmoney.xyz/account/meld/logo-main.svg'

export const popularTokens = ['BTC', 'ETH', 'USDC', 'SOL']
