import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons'
import { FlexRow, PaymentMethodLogo } from './styled'
import getMobileScreenWidth from '../services/getMobileScreenWidth'
import { List } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentMethod } from 'store/slices/wizard'
import { ReactComponent as ChevronSvg } from './icons/chevron-down-icon.svg'
import Icon from '@ant-design/icons'


const DownIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.downIcon};
  font-size: 12px;
  margin-top: 6px;
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 9;
  backdrop-filter: blur(10px);
  border-radius: 6px 6px 0 0;
  background: rgb(49 48 48 / 30%);
`

const FlexCol = styled(FlexRow)`
  width: 100%;
  cursor: ${({ cursor }) => cursor};
`
const Drawer = styled.div`
  color: #000000;
  position: absolute;
  left: 0;
  right: 0;
  height: 93%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.drawerBackgoundPrimary};
  z-index: 11;
  padding: 15px;
  display: ${({height}) => height};
  transform: translateY(100%);
	-webkit-transform: translateY(100%);
  overflow: hidden;
`

const Header = styled.div`
  color: ${({ theme }) => theme.colors.drawerHeaderColor};
  text-align: left;
  font-size: 18px;
  position: relative;
  font-weight: 500;
  margin-bottom: 25px;
  padding-top: 8px;
`

const SearchContent = styled.div`
  font-size: 14px;
  // height: ${({drawerPosition}) => drawerPosition === 'center' ? '40%' : '70%' };
  overflow-y: ${({ display }) => display ? 'hidden' : 'auto'};
  padding-right: 10px;
  box-sizing: content-box;
  ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`

const ListNew = styled(List)`
  .ant-list-item {
    color: ${({ theme }) => theme.colors.drawerListTextColor} !important;
    padding: 10px 8px;
  }
  .ant-list-item-meta-title
  {
   color: ${({ theme }) => theme.colors.foregroundPrimary};
  }
  .ant-list-item-meta-content,
  .ant-list-item-meta-description {
    color: ${({ theme }) => theme.colors.foregroundQuaternary};
  }
  .ant-list-item:hover,
  .ant-list-item-meta:hover,
  .ant-list-item-meta-title:hover,
  .ant-list-item-meta-content:hover,
  .ant-list-item-meta-description:hover {
    /* color: #000000d9 !important; */
  }
`

const ListItem = styled(List.Item)`
  text-align: ${({ align }) => align};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  color: #ffffff !important;
  display: flex;
  align-items: center;
  border-bottom: 0px !important;

  &:hover {
    background: ${({ theme }) => theme.colors.cardBackground};
    color: #ffff !important;
    border-radius: 8px
  }
`

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  padding: 5px;
  border-radius: 70%;
  border: 1px solid #333741;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AvaratImg = styled.img`
  width: 100%;
  height: 100%;
`

const ListItemMeta = styled(List.Item.Meta)`
  align-items: center;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`

const DrawerPaymentMethod = ({ filterPaymentMethod, defaultPaymentMethod, disabled, header, onOpen, onClose, onSelect }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [ openDrawer, setOpenDrawer ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState(defaultPaymentMethod)
  const isMobileScreen = getMobileScreenWidth()
  const { paymentMethod, lockPaymentMethod } = useSelector((state) => state.wizard)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [ drawerPosition, setDrawerPosition ] = useState('bottom-center')
  const [ drawerClass, setDrawerClass ] = useState('slide-bottom-center')
  const minSwipeDistance = 5

  const resetDrawer = (isBackButtonClicked) => {
    setTimeout(() => {
      setOpenDrawer(false)
    }, 200)
    setTimeout(() => {
      setIsOpen(false)
    }, 500)
    if (isBackButtonClicked) {
      onClose()
    }
  }

  useEffect(() => {
    setSelectedPaymentMethod(defaultPaymentMethod)
  }, [defaultPaymentMethod])

  let handleClick = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    dispatch(setPaymentMethod(paymentMethod))
    onSelect(paymentMethod)
    closeDrawer()
  }

  // const sortCardsByPopularity = (firstElement, secondElement) => {
  //   const cardsPopularityOrder = ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS', 'DISCOVER', 'JCB', 'DINERS_CLUB', 'DLOCAL']
  //   return cardsPopularityOrder.indexOf(firstElement.paymentMethod) - cardsPopularityOrder.indexOf(secondElement.paymentMethod)
  // }

  const renderAvatar = (item) => {
    return (
      item?.logos?.dark &&
        <Avatar>
          <AvaratImg src={theme?.name === 'darkMode' ? item?.logos?.dark : item?.logos?.light} alt={item.name} />
        </Avatar>
    )
  }

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isUpSwipe = distance > minSwipeDistance
    const isDownSwipe = distance < -minSwipeDistance
    if (isUpSwipe || isDownSwipe) {
      dragDrawer(isUpSwipe)
    }
  }

  const dragDrawer = (isUpSwipe) => {
    let closeDrawer = false
    if (isUpSwipe) {
      if (drawerPosition === 'center-top') {
        updateDrawerConfig('slide-center-top', 'top-center')
      }
      if (drawerPosition === 'center-bottom') {
        updateDrawerConfig('slide-center-top', 'top-center')
      }
    } else if (!isUpSwipe) {
      if (drawerPosition === 'top-center') {
        updateDrawerConfig('slide-top-center', 'center-bottom')
      }
    }
    if (closeDrawer) {
      resetDrawer()
    }
  }

  const changeDrawerHeight = () => {
    let closeDrawer = false
    if (drawerPosition === 'bottom-center') {
      updateDrawerConfig('slide-bottom-center', 'center-top')
    }
    if (drawerPosition === 'center-top') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
    if (drawerPosition === 'top-center') {
      updateDrawerConfig('slide-top-center', 'center-bottom')
    }
    if (drawerPosition === 'center-bottom') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
    if (closeDrawer) {
      resetDrawer()
    }
  }

  const onScroll = (e) => {
    if (drawerPosition === 'center-top') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
    if (drawerPosition === 'center-bottom') {
      updateDrawerConfig('slide-center-top', 'top-center')
    }
  }

  const closeDrawer = () => {
    if (drawerPosition === 'center-top' || drawerPosition === 'center-bottom') {
      updateDrawerConfig('slide-center-bottom', 'bottom-center')
      resetDrawer(true)
      return
    }
    updateDrawerConfig('slide-top-bottom', 'bottom-center')
    resetDrawer(true)
  }

  const updateDrawerConfig = (drawerClassName, position) => {
    setDrawerClass(drawerClassName)
    setDrawerPosition(position)
  }

  return (
    <>
      <FlexRow key={selectedPaymentMethod?.paymentMethod}>
        <FlexCol cursor={!disabled && filterPaymentMethod.length > 1 ? 'pointer' : 'not-allowed'} onClick={() => {
            if(!disabled && filterPaymentMethod.length > 1) {
              setOpenDrawer(true)
              onOpen()
              setIsOpen(true)
              changeDrawerHeight()
            }
          }}>
          <span>
            <PaymentMethodLogo style={{ marginTop: '-3px' }} alt={`${selectedPaymentMethod?.name} logo`} src={selectedPaymentMethod?.logos[theme?.name === 'darkMode' ? 'dark' : 'light']} />
            {selectedPaymentMethod?.name}
          </span>
          { filterPaymentMethod.length > 1 && !disabled && <DownIcon component={ChevronSvg} /> }
        </FlexCol>
      </FlexRow>
      {openDrawer && <Blur/>}
      <Drawer
        onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
        height={!isOpen && 'none'}
        className={drawerClass}
      >
        <Header>
          <MinusOutlined style={{ position: 'absolute', right: 0, left: 0, top: '-12px' }} onClick={() => changeDrawerHeight()} />
          <CloseOutlined style={{ position: 'absolute', right: 8, top: 4, zIndex: 9, padding: '10px' }} onClick={() => closeDrawer()} />
          {header}
        </Header>
        <SearchContent ismobilescreen={isMobileScreen} style={{ height: `${drawerPosition === 'center' ? '83%' : '90%'}` }} onScroll={onScroll}>
          <ListNew
            size="small"
            bordered={false}
            dataSource={filterPaymentMethod}
            renderItem={(item) =>
              (
                <ListItem
                  onClick={() => {
                    if (!((lockPaymentMethod && paymentMethod?.paymentMethod !== item.paymentMethod))) {
                      handleClick(item)
                    }
                  }}
                  disabled={(lockPaymentMethod && paymentMethod?.paymentMethod !== item.paymentMethod)}
                >
                  <ListItemMeta
                    avatar={renderAvatar(item)}
                    title={item.name}
                    disabled={(lockPaymentMethod && paymentMethod?.paymentMethod !== item.paymentMethod)}
                  />
                  {paymentMethod?.paymentMethod === item?.paymentMethod && <CheckOutlined style={{ color: '#F969D3',fontSize:'20px' }} />} {/* Render the tick icon if the current item is the selected item */}
                </ListItem>
              )
            }
          >
          </ListNew>
        </SearchContent>
      </Drawer>
    </>
  )
}

export default DrawerPaymentMethod
